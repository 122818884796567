<template>
  <div id="app">
    <HeaderApp></HeaderApp>
    <router-view></router-view>
    <FooterApp></FooterApp>
    <Notifs></Notifs>
  </div>
</template>

<script>
import HeaderApp from './components/HeaderApp'
import FooterApp from './components/FooterApp'
import Notifs from './components/Notifs'

export default {
  name: 'App',
  components : {
    HeaderApp,
    FooterApp,
    Notifs
  }

}
</script>

<style>

@font-face {
  font-family: "Arimo";
  font-weight: 400;
  src: url("./assets/fonts/Arimo-Regular.ttf");
}

@font-face {
  font-family: "Arimo_bold";
  font-weight: 700;
  src: url("./assets/fonts/Arimo-Bold.ttf");
}

@font-face {
  font-family: "Arimo_italic";
  font-weight: 400;
  src: url("./assets/fonts/Arimo-Italic.ttf");
}

@font-face {
  font-family: "Arimo_bold_italic";
  font-weight: 700;
  src: url("./assets/fonts/Arimo-BoldItalic.ttf");
}

body {
  margin:0;
  padding:0;
}

.main {
  min-height:calc(100vh - 185px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  width:100%;
  border-collapse: collapse;
}

thead th {
  font-family: 'Arimo_bold';
}

td {
  font-family: 'Arimo';
}

thead th {
    background:#ef7941;
    color:#fff;
    text-align:center;
    padding:10px 0;
}

thead th:first-child {
    border-top-left-radius: 10px;
}

thead th:last-child {
    border-top-right-radius: 10px;
}

td {
    color:#a4a5a6;
    text-align: center;
    padding:10px 5px;
    font-size:15px;
}

tr:nth-child(even) td {
    background:#f6f5f5;
}

tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
</style>
