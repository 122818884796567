import { extend } from 'vee-validate';
import { required, max, min, digits, email, mimes, size, length, confirmed, integer, between, regex} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: () => 'This field is required'
  });

  extend('is_not', {
    ...required,
    message: () => 'Ce champ est requis'
  });

  extend('mimes', {
    ...mimes,
    message: 'Le type du fichier est invalide'
  });

  extend('size', {
    ...size,
    message: 'La taille du fichier dépasse 3Mo'
  });

  extend('max', {
    ...max,
    message: (_, { length }) => `This field cannot contain more than ${length} caracters`
  });

  extend('length', {
    ...length,
    message: (_, { length }) => `Ce champ doit comporter  ${length} caractères`
  });

  extend('min', {
    ...min,
    message: (_, { length }) => `This field cannot contain less than ${length} caracters`
  });

  extend('digits', {
    ...digits,
    message: (_, { length }) => `Ce champ doit contenir ${length} chiffres`
  });

  extend('integer', {
    ...integer,
    message: 'Ce champ ne peut contenir que des nombres entiers'
  });

  extend('between', {
    ...between,
    message: (_, { min, max }) => `Ce champ doit être compris entre ${min} et ${max}`
  });

  extend('email', {
    ...email,
    message: 'Le format de l\'email est invalide'
  });

  extend('confirmed', {
    ...confirmed,
    message: 'Les champs ne concordent pas'
  });

  extend('regex', {
    ...regex,
    message: 'RGPD'
  });

 extend('rgpd_password', {
    message:  'Le mot de passe doit contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 carctère spécial',
    validate: value => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        return strongRegex.test(value);
    }
});
