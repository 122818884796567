<template>
    <div class="notifs-container" v-if="$store.state.NOTIFS && $store.state.NOTIFS.length > 0">
            <div :class="['notif-item', item.type == 1 ? 'success' : 'error']" v-for="(item, idx) in this.$store.state.NOTIFS" :key="idx">
                <div class="notif-close" @click="removeNotif(idx)"></div>
                <p class="notif-content" v-html="item.content"></p>
            </div> 
    </div>
</template>

<script>
export default {
    name : 'Notifs',
    methods : {
        removeNotif(index) {
            this.$store.commit('removeNotif', index)
        }
    }
}
</script>

<style  scoped>
    .notifs-container {
        position:fixed;
        right:0;
        bottom:2%;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        max-height: 90%;
        z-index:9999;

    }

    .notif-item {
        position: relative;
        display: flex;
        justify-content: center;
        padding:20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width:250px;
        max-width: 100%;
        margin:5px 0;
        opacity: 0;
    }

    .notif-content {
        color:#fff;
        font-size:13px;
        font-family: 'Armimo_bold';
        padding:0 10px;
        max-width: calc(100% - 20px);
        overflow: auto;
        max-height: 90vh;
    }

    .notif-item.success {
        background: #879C37;
    }

    .notif-item.error {
        background: #e60600;
    }

    .notif-close {
        position: absolute;
        top:8px;
        right:8px;
        height:15px;
        width:15px;
        background-image: url('./../assets/icon-close.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor:pointer;
    }

    /* animation */
    .notif-item.success {
        animation : success 5s;
    }

    @keyframes success {
        0% {
            margin-right: -400px;
            opacity: 0;
        }
        15% {
            margin-right:0;
            opacity: 1;
        }
        85% {
            margin-right:0;
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .notif-item.error {
        animation : error 0.5s;
        opacity: 1;
    }

    @keyframes enter {
        0% {
            margin-right: -400px;
            opacity: 0;
        }
        100% {
            margin-right: 0;
            opacity: 1;
        }
    }
</style>