import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['JWT_TOKEN', 'USER' ]
  })],
  state: { 
      //URL : 'http://localhost/MVC_gamification/public/api/',
      URL : 'https://api-gamification.my-virtual-convention.com/api/',
      JWT_TOKEN : null  ,
      USER : {
        isLoged : false,
        infos  : {
            id : null,
            role : null
        }
      },
      NOTIFS : [],

  },
  mutations: {
    setToken(state, token) {
      state.JWT_TOKEN = token
    },
    login(state, infos) {
      state.USER.isLoged = true
      state.USER.infos = infos
    },
    logout(state){
      state.JWT_TOKEN = null
      state.NOTIFS = []
      state.USER = {
        isLoged : false,
        infos : {
            id : null,
            role : null
        }
      }
    },
    addNotif(state, notif) {
      state.NOTIFS.push(notif)    
    },
    removeNotif(state, key) {
      state.NOTIFS.splice(key, 1)
    },

  },
  getters: {
    getURL(state) {
      return state.URL
    },
    getToken(state) {
      return state.JWT_TOKEN
    },
  }, 
  actions: {
    addNotif({commit, state}, notif) {
      commit('addNotif', notif)
      if(notif.type === 1) {
        const key = state.NOTIFS.length - 1
        setTimeout(() => {
          commit('removeNotif', key)
        }, 5000)
      }


    }
  }
})