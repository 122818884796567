<template>
    <div class="header">
        <img src="./../assets/logo.png" alt="logo" />
        <div class="logout" v-if="$store.state.USER.isLoged" @click="logout"></div>
    </div>
</template>

<script>
export default {
    name :'HeaderApp',
    methods : {
        logout() {
            this.$store.commit('logout')
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>
    .header {
        width:100%;
        height:110px;
        display:flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.35);
        -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.35);
        position:relative;
    }

    .header img {
        height:90px;
        width:auto;
    }

    .logout {
        position:absolute;
        top:30px;
        right:40px;
        height:50px;
        width:50px;
        background-image: url('./../assets/logout.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        transition:0.5s;
    }

    .logout:hover {
        background-image: url('./../assets/logout-o.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }


</style>