<template>
    <div class="footer">

    </div>
</template>

<script>
export default {
    name : 'FooterApp',

}
</script>

<style scoped>
    .footer {
        height:55px;
        width:calc(100% - 20px);
        padding:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background:#335c68;
    }

</style>