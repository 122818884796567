import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './vee-validate'
import axios from 'axios'

Vue.config.productionTip = false

axios.interceptors.response.use(response => {
  if(response.status != 401)
    return response;
  else {
    store.commit('logout')  
    router.push('/') 
    store.dispatch('addNotif', {type : 2, content : 'Invalid token access'})  
    
  }
});

new Vue({
  router :router,
  store,
  render: h => h(App),
}).$mount('#app')
