import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
        return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'Login',
      component: () => import( './views/Login.vue'),
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/codes',
      name: 'Codes',
      component: () => import( './views/Codes.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 2
      },
    },
    {
      path: '/winners',
      name: 'Winners',
      component: () => import( './views/Winners.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 2
      },
    },
    {
        path: '/parameters',
        name: 'Parameters',
        component: () => import( './views/Parameters.vue'),
        meta: {
          requiresAuth: true,
          requiresRole: 2
        },
      },
]

  
})

/* verification qu'on est connecté sinon on redirige */
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(to.matched.some(record => record.meta.requiresRole === store.state.USER.infos.role) ) {
      next()
      return
    } 
    next('/')
  }else{
    next() 
  }  
})


export default router;
